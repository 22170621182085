<template>
  <div>
    <div class="divider"></div>
    <div class="title">Log in to your account</div>
    <div class="subtitle">Enter your username and password to get started.</div>
    <form
      class="logIn-form"
    >
      <div class="form-title">
        <!-- <div>
          <span class="form-title-right">Forgotten your username?</span>
        </div> -->
      </div>
      <BaseInput
      :input_value ='username'
      @handleUpdateValue ='val=> username=val'
      placeHolder = 'Username'
      :isAutoFocus='true'
      ></BaseInput>
      <div class="form-title">
        <!-- <div>
          <span class="form-title-right">Forgotten your password?</span>
        </div> -->
      </div>
      <BaseInput
      :input_value.sync ='password'
      @handleUpdateValue ='val=> password=val'
      placeHolder = 'Password'
      i_type = 'password'
      ></BaseInput>
      <input
        class="btn margintop32"
        type="submit"
        value="LOG IN"
        name=""
        @click.prevent="submitForm('ruleForm')"
      />
      <input
        class="signUpBtn"
        type="button"
        value="SIGN UP"
        name=""
        @click="handleSignClick"
      />
    </form>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue'
import { setLocalStorage } from '@/utils/localStorage'
import { getToken, getCustomerProfile } from '@/api/common'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  created () {
  },
  components: {
    BaseInput
  },
  methods: {
    ...mapActions('login', [
      'SetTokenAction',
      'SetHasLogInAction',
      'setUserDetailProfileAction'
    ]),
    handleLoginClick () {
      getToken().then(res => console.log(res))
    },
    handleSignClick () {
      this.$router.push('/signup')
    },
    submitForm () {
      const query = {
        username: this.username,
        password: this.password

      }
      getToken(query).then(({ status, token }) => {
        if (status === 'Failed') {
          this.$message.error('Incorrect account/password')
        } else if (status === 'Success') {
          this.SetTokenAction(token)
            .then(getCustomerProfile)
            .then(res => {
              setLocalStorage('user', res)
              this.setUserDetailProfileAction(res)
            })
            .then(() => {
              this.$router.push('/user/dashboard')
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 160px auto 0;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: #163257;
  text-transform: uppercase;
  text-align: center;
  margin-top: 12px;
}
.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-top: 6px;
}
.logIn-form {
  width: 600px;
  margin: 40px auto 0;
  padding: 50px 60px 60px 60px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 160px;
}
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #163257;
  line-height: 22px;
}
.form-title-right {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
}
.login-input >>> .el-input__inner{
  border-radius: 2px;
  box-sizing: border-box;
  padding-left: 16px;
  font-size: 18px;
  width: 100%;
  height: 56px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 5px;
}
.margintop20 {
  margin-top: 20px;
}
.margintop32 {
  margin-top: 32px;
}
.btn {
  height: 56px;
  width: 100%;
  border-radius: 2px;
  background: #163257;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
}
.signUpBtn {
  height: 56px;
  width: 100%;
  border-radius: 2px;
  color: #163257;
  font-size: 20px;
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
@media only screen and (max-width: 600px){
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 12vh auto 0;
}
.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #163257;
  text-transform: uppercase;
  text-align: center;
  margin-top: 2vh;
}
.subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  margin-top: 1vh;
}
.logIn-form {
  width: 90vw;
  margin: 3vh auto 0;
  padding: 5vw;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 10vh;
}
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: #163257;
  line-height: 22px;
}
.form-title-right {
  font-size: .75rem;
  color: rgba(0, 0, 0, 0.25);
}
.login-input >>> .el-input__inner{
  border-radius: 2px;
  box-sizing: border-box;
  padding-left: 16px;
  font-size: 18px;
  width: 100%;
  height: 7vh;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 5px;
}

.margintop20 {
  margin-top: 2vh;
}
.margintop32 {
  margin-top: 2vh;
}
.btn {
  height: 7vh;
  width: 100%;
  border-radius: 2px;
  background: #163257;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 1.25rem;;
  color: #ffffff;
  cursor: pointer;
}
.signUpBtn {
  height: 6vh;
  width: 100%;
  border-radius: 2px;
  color: #163257;
  font-size: 1.25rem;
  background: #ffffff;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
}
</style>
